.rtl {
    direction: rtl;
    text-align: right;
    .content-area .section.cols .col ol, .content-area .section.cols .col-md-6 ol, .content-area .section.row .col ol, .content-area .section.row .col-md-6 ol {
        margin-left: initial;
        margin-right: 18px;    
    }
    .modal .modal-footer > * {
        display: flex;
        flex-direction: row-reverse;
    }
    .main .sub-wrapper .diary-title p, 
    .main .section .diary-title p, 
    .header .sub-wrapper .diary-title p, 
    .header .section .diary-title p {
        margin-right: 30px;
    }
    .main .button-cont .continue1 img, 
    .header .button-cont .continue1 img {
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg);
    }
    .main .button-cont .continue1 #reverse, 
    .header .button-cont .continue1 #reverse {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    .main .sub-wrapper.spine #help-button h4, .main .sub-wrapper.spine #help-button .h4, .main .section.spine #help-button h4, .main .section.spine #help-button .h4, .header .sub-wrapper.spine #help-button h4, .header .sub-wrapper.spine #help-button .h4, .header .section.spine #help-button h4, .header .section.spine #help-button .h4 {
       
        right: initial;
        left: 0px;
    }
    .main .sub-wrapper.spine #help-icon, .main .sub-wrapper.spine .help-icon, .main .section.spine #help-icon, .main .section.spine .help-icon, .header .sub-wrapper.spine #help-icon, .header .sub-wrapper.spine .help-icon, .header .section.spine #help-icon, .header .section.spine .help-icon {
        left: -44px;
        right: initial;
    }
}

@media (pointer: fine) {
    .rtl {
        .header > .nav .logo {
            right: 165px !important;
            left: initial !important;
        }
        .header .nav-items {
            right: initial;
            left: 0;
        }
        .main .sub-wrapper .diary {
            .days-comp,  .days-sub {
                text-align: left;
            }
            #occasions .cat-vertical .meal .catlabel {
                flex-direction: row;
                span {
                    min-width: 50%;
                    text-align: right;
                }
            }            
        }    
    }
}    