#page-occasion_courses {
	.header > .nav .logo {
		left: 30px;
		display: inline-block;
		width: 81px;
		height: 57px;
		position: relative;
		top: -26px;
		z-index: 10;
	}
	.pad1{
		justify-content: center;	
		border-top: none;
		padding: floor(calc(($grid-gutter-width)*2)) 0 floor(($grid-gutter-width)) 0;
	}
	.button-cont{
		justify-content: center;
	}
	.diary-side{
		background-color: transparent;
	}
	.main{
		.sub-wrapper{
			top: 0;
		}
	}
	.spine{
		-webkit-transform: initial;
		transform: initial;		
	}

}


@media (pointer: fine) {

	#page-occasion_courses {
		.spine {
			box-shadow: 4px 6px 15px 0px $shadowcolor;
			transform: translateX(floor(calc(($grid-gutter-width)*3)))	
		}
		.header > .nav .logo {
			left: -20px;
			display: inline-block;
			width: 201px;
			height: 147px;
			position: relative;
			top: 18px;
			z-index: 10;
		}	
	}	
}