#page-occasion_attributes, #page-occasion_attendance{
	&.mobile{
		.diary-side{display: none}
		.attendee{
			&.form-row > .col:last-of-type, &.form-row > [class*=col-]:last-of-type {
				padding-right: 8px !important;
			}
		}		
	}
	&.ooh {
		#attribute_questions > div.question-group:not(.hidden) {
			margin: 10px auto;
		}
	}
	select {
		background: $lightblue;
		color: #fff;	
		&:hover{ 
			cursor:pointer;
		}		
		option {
			background: inherit;
			color: inherit;
		}
	}
	#attendance{
		margin-bottom: floor(($grid-gutter-width));
	}
	.all_ate{
		padding-top: 0px;
		input{
			margin-left: floor(calc(($grid-gutter-width)/5));
		}
	}
	.dark-blue{
		font-size: 20px;
		padding: 0;
		width: 20px;
		line-height: 1;
	}
	.btn-xs{
		padding: 5px;
		line-height: .5;
		align-items: center;		
	}
	.image_attribs_top_right {
		top: 30px!important;
	}
	.meal_att_column {
		flex: 1 1 0; flex-grow: 1; flex-shrink: 1; flex-basis:0px;
		text-align: center;
		margin-bottom: 0;
		&:hover{ 
			cursor:pointer;
		}		
		input{
		    vertical-align: bottom;
		}
	}
	.all_ate{
		&:hover{ 
			cursor:pointer;
		}		
	}
    .attendee{
		margin-bottom: 0;
		border-top: 1px solid $lightgrey;
        ul {
            width: 100%;
            display: flex;
            flex-direction: row;
            list-style: none;
            li{
                flex: 1 1 100%;
            }
        }
		select {
			padding: 6px 12px 6px 3px;
			width: 100%;
		}
		&.form-row > .col:last-of-type, &.form-row > [class*=col-]:last-of-type {
			padding-right: 0;
		}
		&#guests{
			padding-top: floor(calc(($grid-gutter-width)/5));
		}
		#guests_text{font-size:0.9rem}
	}

	  

	  
	  .double-list li::marker {
		content: counter(count, decimal) ') ';
	  }
	#attribute_questions {
		counter-reset: questions 0;
		display: flex;
		flex-wrap: wrap;
		 > div {
			margin: 10px 0px;
			padding: 4px;
			
		}
		> div:not([style*="display: none"]){
			counter-increment: count 1;
		}
		.questionheading{
			font-size: ($font-size-base * 1.5);
		}
		.button-cont{
			border-top: 1px solid $lightgrey;
		}
		label{
			display: flex;
			margin-bottom: floor(calc(($grid-gutter-width)/5));
			width: 100%;
			.label-text {
				background-color: $lightgrey;
				margin-left: floor(calc(($grid-gutter-width)/5));
				width: 100%;
			}	
			&:hover{ 
				cursor:pointer;
				.label-text{
					background-color: $darkgrey;
				}
			}
		}
		input[type="radio"] {
		  /* -webkit-appearance: checkbox;  Chrome, Safari, Opera */
		  /* -moz-appearance: checkbox;     Firefox */
		  /* appearance: checkbox;      not currently supported */
		  &:checked ~ *{
			background-color: $darkgrey;
		  }
		}
		label.checked{}
		input[type="radio"], input[type="checkbox"]  {
			&:checked + .label-text {
				background-color: $darkgrey;
			}
		}
		.col-md-6, .col-xs-6{
			margin-bottom: floor(($grid-gutter-width));
		}
	}	
}	
