.darkmode{
	background-color: #000;

	p, li{
		color: #fff;
	}
	.fill-change, .icon-fill,
	.fill-change>img>path,.icon-fill>img>path{
		fill: #fff;
		filter: invert(1);
	}
	.spine{
		box-shadow: none !important;
	}
    .icon-stroke{
		stroke: #fff
	}
    .sub-wrapper{
		&::before {
			content: '';
			box-shadow: none !important;
		}
	}
	.diary-side p, .diary-side li,
	.summary p, .summary li,
	.spine p, .spine li,
	.tab_question  p, .tab_question li{
		color: #000;
	}
	.content-area .section{
		h2 .caret{              
			&::after{
				background-image: url('data:image/svg+xml;utf8,<svg width="100" height="50" xmlns="http://www.w3.org/2000/svg"><polygon points="0,0 100,0 50,50" style="fill:%23FFF;" /></svg>') !important;
			}
		}
		.faq-item {
			.card-body{
				color: #fff;
			}
			.btn-link{
				color: #fff
			}
		}
	}	
}
.lightmode{
	background-color: #fff;
	p, li{
		color: #000;
	}
    .fill-change,
	.fill-change>img>path{
		fill: #000
	}
    .icon-fill,
	.icon-fill>img>path{
		fill: #0060FF
	}
    .icon-stroke{
		stroke: #0060FF
	}
    //$('.sub-wrapper').removeClass('shadow');
    .spine{
		box-shadow: '4px 6px 15px #aaaaaa'
	}
	.content-area .section{
		.faq-item {
			.card-body{
				color: #000;
			}
			.btn-link{
				color: #000
			}
		}
	}	
}