.main {
    position: relative
}

.btn img {
    height: 14px;
}

.graphic {
    flex: 1;
    position: absolute;
    width: 100%;
    top: 110px;
    left: 0;
    height: 100vH;
    height: calc(100vH - 110px);
    img {
        max-height: 100%;
        max-width: 100%;
    }
    .sub-left-img{
        position: relative;
        float: left;
        height: inherit;
        overflow-y: inherit;
        z-index: 1;
        &#left-img-width1 {
            width: 13vw;
            z-index: 0;
        }
        .sideimg {
            position: absolute;
            display: block;
        }
        #img-1 {
			right: 10px;
			width: 260px;
			top: 120px;
			padding: 0 2vw 0 0;
        }
        #img-2 {
            right: 30px;
            max-width: 350px;
            top: 375px;
            padding: 0 2vw 0 1vw;
        }
        #pizza {
                right: 35px;
                max-width: 490px;
                top: 280px;
                padding: 0 2vw 0 1vw;
        }				
        #img-3 {
            right: 15px;
            max-width: 200px;
            top: 660px;
        }
        #img-4 {
            left:110px;
            max-width: 140px;
            top: 360px;
        }
        #img-5 {
            left: 50px;
            max-width: 190px;
            top: 160px;
        }
        #img-6 {
            left: -25px;
            max-width: 190px;
            top: 510px;
        }
        #img-7 {
            left:140px;
            max-width: 320px;
            top: 660px;
        }
        #breakfast {
            height: 800px;
            top: 120px;
        }
    }
    .sub-right-img{
        position: absolute;
        width: 17.5vW;
        right: 0;
        top: 0;
		overflow-x: hidden;
		overflow-y: hidden;
		height: 100vH;
        max-height: 100vH;
		height: calc(100vH - 110px);
        z-index: 1;
        .sideimg {
            position: absolute;
            display: block;
        }
        #img-1 {
			left: 10px;
			width: 260px;
			top: 120px;
			padding: 0 2vw 0 0;
        }
		.image_day_overview_bottom_left{
			left: 70px;
			width: 260px;
			top: 10px;
			padding: 0 2vw 0 0;
		}
        #img-2 {
            left: 30px;
            max-width: 375px;
            top: 375px;
            padding: 0 2vw 0 1vw;
            }
            #strawb {
                left: 30px;
                max-width: 240px;
                top: 375px;
                padding: 0 2vw 0 1vw;
                } 
        #soup {
            left: 20px;
            max-width: 315px;
            top: 335px;
        }
        #salmon {
            left: 120px;
            max-width: 260px;
            top: 140px;
        }
        #chips {
            left: 10px;
            max-width: 240px;
            top: 400px;
        }
        #doughnuts {
            left: 200px;
            max-width: 190px;
            top: 590px;
        }
        #coffee {
            top: 670px;
            width: 155px;
            left: 0;
        }
        #pasta {
            top: 630px;
            width: 400px;
            left: 0;
        }				 
    }
}

.graphic-item {
    position: absolute;
    -o-object-fit: contain;
    object-fit: contain;
    -o-object-position: center;
    object-position: center;
    display: block
}

.graphic-item img {
    -o-object-fit: contain;
    object-fit: contain;
    -o-object-position: center;
    object-position: center;
    display: block
}

.graphic-item#chicken {
    top: 60px;
    left: -50px
}

.graphic-item#chicken img {
    max-width: 335px;
    height: 335px
}

.graphic-item#drink1 {
    top: 460px;
    left: 160px
}

.graphic-item#drink1 img {
    max-width: 120px;
    height: 120px
}

.graphic-item#fish {
    bottom: -150px;
    left: 240px
}

.graphic-item#fish img {
    max-width: 310px;
    height: 310px
}

.graphic-item#coffee {
    top: 40px;
    right: 20px;
}

.graphic-item#coffee img {
    max-width: 170px
}

.graphic-item#sandwich {
    bottom: -100px;
    right: 0
}

.graphic-item#sandwich img {
    max-width: 270px;
    height: 270px
}

#page-index{
	.image_home_top_left1{
	}
	.image_home_top_left2{
		top: 570px;
	}
	.image_home_middle_left1{
		top: 400px;
	}
	.image_home_middle_left2{
		top: 450px;
		left: 35px;
	}
	.image_home_middle_left3{
		top: 200px;
		left: -120px;
	}
	.image_home_bottom_left1{
		top: 40px;
		left: 100px;
		z-index: 3;
	}
	.image_home_bottom_left2{
		top: 100px;
		left: -44px;
	}
	.image_home_bottom_left3{
		top: 300px;
		left: 90px;
	}
}


#page-index .sub-left-img {
    top: 0;
    width: 700px;
    -o-object-fit: contain;
    object-fit: contain;
    height: 1611px;
    max-height: 100vh;
}

#page-index .image_home_top_left2 {
    top: 570px
}

#page-index .image_home_middle_left1 {
    top: 400px
}

#page-index .image_home_middle_left2 {
    top: 450px;
    left: 35px
}

#page-index .image_home_middle_left3 {
    top: 200px;
    left: -120px
}

#page-index .image_home_bottom_left1 {
    top: 40px;
    left: 100px;
    z-index: 3
}

#page-index .image_home_bottom_left2 {
    top: 100px;
    left: -44px
}

#page-index .image_home_bottom_left3 {
    top: 300px;
    left: 90px
}
